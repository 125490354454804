import React from 'react';
import { HashLink } from 'react-router-hash-link';

const NavLinks = () => {
  return (
    <>
      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:primary-text text-lg"
        smooth
        to="/#about"
      >
        About
      </HashLink>
      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:primary-text text-lg"
        smooth
        to="/#products"
      >
        Products
      </HashLink>
      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:primary-text text-lg"
        to="/contact#contact"
      >
        Contact Us
      </HashLink>
    </>
  );
};

export default NavLinks;
